import {useActionData} from '@remix-run/react';
import {useEffect} from 'react';
import {useToastAdvanced} from '#/app/hooks/useToastAdvanced.js';

// TODO: Move this into useFormAdvanced as a flag that can be turned on or off ???
export const useToastErrors = (paramData?: any) => {
  const {show} = useToastAdvanced();
  const actionData = useActionData() as any;
  const data = paramData ?? actionData;

  useEffect(() => {
    (data?.errors ?? []).forEach((e: any) => {
      if ('path' in e) {
        show({description: e.message, title: e.path.length ? e.path.join(' / ') : 'Form Error', variant: 'error'});
      } else {
        show({description: e.message, variant: 'error'});
      }
    });
  }, [data, show]);
};
